import React, {Suspense, useEffect, useState, useContext} from "react"
import { Popups } from "./popups";
import { BlocksContext } from "../context/blocksContext"
import { Menu } from './menu';
import { Main_screen } from './main_screen';
import { Advantages_house } from './advantages_house';



export const Loader = () => {
    //const [loaded, setLoaded] = useState(2)
    const Special_offer = React.lazy(() => import("./special_offer"))
    const Large_yard = React.lazy(() => import("./large_yard"))
    const Loc = React.lazy(() => import("./loc"))
    const Important = React.lazy(() => import("./important"))
    const Excurs = React.lazy(() => import("./excurs"))
    const Transport = React.lazy(() => import("./transport"))
    const Infra = React.lazy(() => import("./infra"))
    const Plan_price = React.lazy(() => import("./plan_price"))
    const Fin_apartments = React.lazy(() => import("./fin_apartments"))
    const Get_flat = React.lazy(() => import("./get_flat"))
    const Galery = React.lazy(() => import("./galery"))
    const How_buy = React.lazy(() => import("./how_buy"))
    const Сommercial_property = React.lazy(() => import("./commercial_property"))
    const Hod_str = React.lazy(() => import("./hod_str"))
    const Develop = React.lazy(() => import("./develop"))
    const Contacts = React.lazy(() => import("./contacts"))
    const Footer = React.lazy(() => import("./footer"))

    const blocksImports = {
        'menu': <Menu />,
        'main_screen': <Main_screen />,
        // 'special_offer': <Special_offer />,
        'advantages_house': <Advantages_house />,
        'large_yard': <Large_yard />,
        'loc': <Loc />,
        'important': <Important />,
        'excurs': <Excurs />,
        'transport': <Transport />,
        'infra': <Infra />,
        // 'plan_price': <Plan_price />,
        'fin_apartments': <Fin_apartments />,
        // 'get_flat': <Get_flat />,
        'galery': <Galery />,
        'how_buy': <How_buy />,
        'commercial_property': <Сommercial_property />,
        'hod_str': <Hod_str />,
        'develop': <Develop />,
        'contacts': <Contacts />,
        'footer': <Footer />,
    }

    
    //const blocks = [<Menu />, <Main_screen />, <Advantages_house />, <Large_yard />, <Loc />, <Excurs />, <Transport />, <Infra />, <Plan_price />, <Fin_apartments />, <Get_flat />, <Galery />, <How_buy />, <Сommercial_property />, <Hod_str />, <Develop />, <Contacts />, <Footer />]
    const blocks = ["menu", "main_screen", /*"special_offer",*/ "advantages_house", "large_yard", "loc", "important", "excurs", "transport", "infra", /*"plan_price",*/ "fin_apartments", /*"get_flat",*/ "galery", "how_buy", "commercial_property", "hod_str", "develop", "contacts", "footer"]

    const loaded = useContext(BlocksContext)

    const LoadBlock = (block) => {
        console.log(blocksImports[block])
        return  <Suspense  fallback={<div>Загрузка...</div>}>{blocksImports[block]}</Suspense>
    }

    const generateHtml = () =>{
        let toDraw = []
        for(let i=0;i<loaded.blocks; i++){
            toDraw.push(LoadBlock(blocks[i]))
        }
        return (
            <div className="blocks" data={loaded.menuClick?"true":""}>
                {loaded.popup?<Popups />:null}
                {toDraw.map((block) => block)}
            </div>
        )
    }
    const  handleScroll = (event) => {

        if (loaded.blocks< blocks.length){
            //console.log(document.querySelector('.blocks section:last-child').offsetTop)
            loaded.setBlocks(20)
            loaded.setPopup(true)
            /*if (((window.innerHeight/2)+window.scrollY)>document.querySelector('section:last-child').offsetTop){
                loaded.setPopup(true)
                let tmp = loaded.blocks+1
                if (tmp==5) tmp=18
                window.removeEventListener('scroll', handleScroll, true);
                if (document.querySelector('.blocks').getAttribute('data')!='true') loaded.setBlocks(tmp)
            }*/
            
        } else {
           window.removeEventListener('scroll', handleScroll, true);
        }
    }
    useEffect(()=> {
        if (loaded.blocks<blocks.length){
            window.addEventListener('scroll', handleScroll, true);
        }
    })
    return generateHtml()
    
}
import React from 'react'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'

export const Advantages_house = () => {
    const hoverAdv = (e) => {

        document.querySelector('.ah_list').style.backgroundImage = "url('"+e.target.getAttribute('background')+"')";
    }
    
    return (
        <section className="advantages_house">
            <div className="ct_main">
                <div className="title_main"><span>Преимущества дома</span></div>
            </div>
            <div className="ah_list">
                <div className="ct_main">
                    <div className="ah_card ah1" onMouseEnter={hoverAdv} background="images/ah_1_bg.jpg">
                        <div className="ahc_img"></div>
                        <span>Велопарковка, <br/> колясочная и <br/> лапомойка</span>
                    </div>
                    <div className="ah_card ah2" onMouseEnter={hoverAdv} background="images/ah_2_bg.jpg">
                        <div className="ahc_img"></div>
                        <span>Разнообразие <br/> планировочных <br/> решений </span>
                    </div>
                    <div className="ah_card ah3" onMouseEnter={hoverAdv} background="images/ah_3_bg.jpg" >
                        <div className="ahc_img"></div>
                        <span>Увеличенные оконные <br/>  проемы с низким  <br/> порогом остекления</span>
                    </div>
                    <div className="ah_card ah4" onMouseEnter={hoverAdv} background="images/ah_4_bg.jpg">
                        <div className="ahc_img"></div>
                        <span>Высокие  <br/> потолки</span>
                    </div>
                    <div className="ah_card ah5" onMouseEnter={hoverAdv} background="images/ah_5_bg.jpg">
                        <div className="ahc_img"></div>
                        <span>Вход в подъезд <br/> с уровня земли</span>
                    </div>
                    <div className="ah_card ah6" onMouseEnter={hoverAdv} background="images/ah_6_bg.jpg">
                        <div className="ahc_img"></div>
                        <span>Специальное помещение <br/> под домофон </span>
                    </div>
                    <div className="ah_card ah7" onMouseEnter={hoverAdv} background="images/ah_7_bg.jpg">
                        <div className="ahc_img"></div>
                        <span>Светопрозрачные <br/> входные группы </span>
                    </div>
                    <div className="ah_card ah8" onMouseEnter={hoverAdv} background="images/ah_8_bg.jpg">
                        <div className="ahc_img"></div>
                        <span>Французские <br/> балконы</span>
                    </div>
                </div>
            </div>
		</section>
    )
}